import axios from "./axios";
// import { setJWT, setUserID, removeJWTAndUserID } from "./cookies";
// import { ROLES } from './constants';
import { parseIdFromLink } from "../utils/helpers";
import startOfToday from "date-fns/startOfToday";
import endOfToday from "date-fns/endOfToday";
// import formatISO from "date-fns/formatISO";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import subMonths from "date-fns/subMonths";
import subMinutes from "date-fns/subMinutes";
import cookies from './cookies';
// import { parseIdFromLink } from './helper';

const axiosGet = async (endpoint) => {
  /* Simple get used to DRY Code
  Currently NOT exported */
  try {
    const res = await axios.get(endpoint);
    return res;
  } catch (error) {
    throw error;
  }
};

const makeAxiosCall = async (callData) => {
  /* {
  method: 'post',
  url: '/user/12345',
  data: {
    firstName: 'Fred',
    lastName: 'Flintstone'
  } */
  return await axios(callData);
};

const getAppData = async () => {
  return axiosGet("/appdata");
};

const getKeys = async () => {
  return axiosGet("/keys");
};

const authenticate = async (email, password) => {
  const endpoint = "/authenticate";
  if (!email || !password) {
    throw new Error("Cannot have empty email or password");
  }
  try {
    return await axios
      .post(endpoint, {
        username: email,
        password: password,
      })
      .then((res) => {
        return res;
      });
  } catch (error) {
    throw error;
  }
};
const spendSummaryWidgetUrlApi = () => {
  const endpoint = "/users/spendSummaryWidgetUrls";
  try {
    return axios.get(endpoint).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const saveMXBankAccntApi = async (params) => {
  const endpoint = "/users/saveBankAccount";
  try {
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};
const memberConnectionStatus = async (params, userID) => {
  const endpoint = "/users/memberConnectionStatus/" + userID;
  try {
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const login = async (email, password, setJWT, setUserID) => {
  //use authenticate, where login is authenticate and assign header
  try {
    return await axios
      .post("/authenticate", {
        username: email,
        password: password,
      })
      .then((res) => {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.token}`;
        return res;
      });
  } catch (error) {
    throw error;
  }
};

const updatePass = async (userID, password) => {
  //use authenticate, where login is authenticate and assign header
  try {
    return await axios
      .patch(`/users/${userID}`, {
        password: password,
      })
      .then((res) => res);
  } catch (error) {
    throw error;
  }
};

const getUserData = async (userLink) => {
  const endpoint = `/users/${userLink}`;
  return axiosGet(endpoint);
};


const getPrefillData = async (data) => {
  const endpoint = "/TKL/prefill";
  try {
    return await axios.post(endpoint, data).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const getUserRole = async (userLink) => {
  const endpoint = `/users/${userLink}/role`;
  return axiosGet(endpoint);
};

const sendReset = async (email) => {
  try {
    await axios
      .post("/users/requestResetEmail", {
        email: email,
      })
      .then((res) => { });
  } catch (error) {
    throw error;
  }
};

const changePassword = async ({ password, token }) => {
  try {
    await axios
      .post("/users/changePassword", {
        password: password,
        token: token,
      })
      .then((res) => { });
  } catch (error) {
    throw error;
  }
};

const getQuizzesByDates = async (dateRange) => {
  const { startDate, endDate } = dateRange;
  const endpoint = `/quizzes/search/findAllByAvailableDateBetween?availableDateEnd=${endDate}&availableDateStart=${startDate}`;
  return axiosGet(endpoint);
};
const getQuizzesByDatesByUserType = async (dateRange, userType) => {
  const { startDate, endDate } = dateRange;
  const endpoint = `/quizzes/search/findAllByAvailableDateBetween?availableDateEnd=${endDate}&availableDateStart=${startDate}&userType=${userType}`;
  return axiosGet(endpoint);
};

const getUsersQuizAnswers = async (userIDLink, quizIDLink) => {
  const endpoint = `/quizAnswers/search/multiParam?user=${userIDLink}&quizzes=${quizIDLink}`;
  return axiosGet(endpoint);
};

const getAllUsersQuizAnswers = async (userID) => {
  const endpoint = `/users/${userID}/quizAnswers`;
  return axiosGet(endpoint);
};
const fetchAllUtmSources = async (userID) => {
  const endpoint = `/fetchAllUtmSources`;
  return axiosGet(endpoint);
};
const fetchAllPartners = async (userID) => {
  const endpoint = `/partner`;
  return axiosGet(endpoint);
};

const fetchTransactionDetails = async (userID) => {
  const endpoint = `/fetchMXTransactionsByUser/164043`;
  return axiosGet(endpoint);
};
const fetchLeadStatuses = async (userID) => {
  const endpoint = `/fetchAllLeadStatus`;
  return axiosGet(endpoint);
};

const postQuizAnswer = async (userIDLink, quizIDLink, answer) => {
  const endpoint = `/quizAnswers/`;
  const body = {
    quiz: quizIDLink,
    user: userIDLink,
    answer,
  };
  try {
    const res = await axios.post(endpoint, body);
    return res;
  } catch (error) {
    throw error;
  }
};

const getTodaysInspiration = async () => {
  const startRange = startOfToday().toISOString(); //Local 00:00:01 converted to UTC
  const endRange = endOfToday().toISOString(); //Local 23:59:59 converted to UTC
  const endpoint = `/inspirations/search/findAllByAvailableDateBetween?availableDateStart=${startRange}&availableDateEnd=${endRange}`;
  return axiosGet(endpoint);
};

const getMonthsInspiration = async (dateRange) => {
  // const startRange = dateRange?.startDate.toISOString() || new Date('01 January 2021').toISOString();
  // const endRange = dateRange?.endDate.toISOString() || today.toISOString();
  const today = cookies.getDate();
  const startRange = dateRange?.startDate || startOfMonth(today).toISOString(); //Local 00:00:01 converted to UTC
  const endRange = dateRange?.endDate || endOfToday().toISOString(); //Local 23:59:59 converted to UTC
  const endpoint = `/inspirations/search/findAllByAvailableDateBetween?availableDateStart=${startRange}&availableDateEnd=${endRange}&sort=availableDate,asc`;
  return axiosGet(endpoint);
};

const getLatestInspiration = async () => {
  /* Goes back to capture all inspirations (incase the curr month isn't covered.) */
  const startDate = new Date("01 January 2022"); //Arbatry date to catch them all
  const startRange = startDate.toISOString(); //Local 00:00:01 converted to UTC
  const endRange = endOfToday().toISOString(); //Local 23:59:59 converted to UTC
  /* []TODO, get size added to the search to control the fetch size */
  const endpoint = `/inspirations/search/findAllByAvailableDateBetween?availableDateStart=${startRange}&availableDateEnd=${endRange}&sort=availableDate,asc&size=3`;
  return axiosGet(endpoint);
};

const postInspiration = async (body) => {
  const endpoint = `/inspirationAnswers/`;
  try {
    // return 'POST Inspiration API OK';
    const res = await axios.post(endpoint, body);
    return res;
  } catch (error) {
    throw error;
  }
};


const getInspirationCommunityResponses = async () => {
  const endpoint = `/inspirationAnswers/popular`;
  return axiosGet(endpoint);
};

const getUsersInspirationAnswerForToday = async (userLink, dateRange) => {
  const startRange = dateRange?.startDate || cookies.getDateRange().startDate; //Local 00:00:01 converted to UTC
  const endRange = dateRange?.endDate || cookies.getDateRange().startDate; //Local 23:59:59 converted to UTC
  const endpoint = `/inspirationAnswers/search/findAllByUserAndCreateDateTimeAfterAndCreateDateTimeBefore?user=${userLink}&answeredDateStart=${startRange}&answeredDateEnd=${endRange}`;
  return axiosGet(endpoint);
};
const getInspireAnswerNewLogic = async (userId, inspirationId) => {

  const endpoint = `/inspirationAnswer/${userId}/${inspirationId}`;
  return axiosGet(endpoint);
};

const getRemoteImage = (id) => {

  const endpoint = `/image/${id}`;
  return endpoint;
};
const getUsersInspirationAnswerForTodayPaginated = async (userLink, dateRange, pageNo, pageSize) => {
  const startRange = dateRange?.startDate || cookies.getDateRange().startDate; //Local 00:00:01 converted to UTC
  const endRange = dateRange?.endDate || cookies.getDateRange().startDate; //Local 23:59:59 converted to UTC
  const endpoint = `/inspirationAnswers/search/findAllByUserAndCreateDateTimeAfterAndCreateDateTimeBefore?user=${userLink}&answeredDateStart=${startRange}&answeredDateEnd=${endRange}&size=${pageSize}&page=${pageNo}`;
  return axiosGet(endpoint);
};



const getUsersMoodAnswerForToday = async (userLink, dateRange) => {
  if (!userLink) return;
  const startRange = dateRange?.startDate || cookies.getDateRange().startDate; //Local 00:00:01 converted to UTC
  const endRange = dateRange?.endDate || cookies.getDateRange().startDate; //Local 23:59:59 converted to UTC

  const endpoint = `/moodAnswers/search/findAllByUserAndCreateDateTimeAfterAndCreateDateTimeBefore?user=${userLink}&answeredDateStart=${startRange}&answeredDateEnd=${endRange}`;
  return axiosGet(endpoint);
};
const getUsersMoodAnswerForTodayPaginated = async (userLink, dateRange, pageNo, pageSize) => {
  if (!userLink) return;
  const startRange = dateRange?.startDate || cookies.getDateRange().startDate; //Local 00:00:01 converted to UTC
  const endRange = dateRange?.endDate || cookies.getDateRange().startDate; //Local 23:59:59 converted to UTC

  const endpoint = `/moodAnswers/search/findAllByUserAndCreateDateTimeAfterAndCreateDateTimeBefore?user=${userLink}&answeredDateStart=${startRange}&answeredDateEnd=${endRange}&size=${pageSize}&page=${pageNo}`;
  return axiosGet(endpoint);
};
const postMood = async (body) => {
  const endpoint = `/moodAnswers/`;
  try {
    // return 'POST Mood API OK';
    const res = await axios.post(endpoint, body);
    return res;
  } catch (error) {
    throw error;
  }
};

const getMoodCommunityResponses = async () => {
  const endpoint = `/moodAnswers/popular`;
  return axiosGet(endpoint);
};

const getUsersFinDataByRange = async (userIDLink, options) => {
  /* can give a date range, or use shortcuts of "prevMonth" or "currMonth" booleans */
  let { startDate, endDate } = options;
  const today = cookies.getDate();
  if (options?.prevMonth) {
    const lastMonthDate = subMonths(today, 1);
    startDate = startOfMonth(lastMonthDate);
    endDate = endOfMonth(lastMonthDate);
  }
  if (options?.currMonth) {
    startDate = startOfMonth(today);
    endDate = endOfMonth(today);
  }
  if (options?.all) {
    startDate = new Date("01 January 2022");
    endDate = endOfMonth(today);
  }

  const fetchSize = options?.size || 20;
  const startDateISO = subMinutes(startDate, 1).toISOString();
  //TODO get server to have <= to start time
  const endDateISO = endDate.toISOString();
  const NEW_FIRST = "createDateTime,desc";
  // const SIZE = 3; //[]TODO Get size added to search api to limit size of fetch
  const currMonthEndpoint = `/finDatas/search/findAllByUserAndCreateDateTimeAfterAndCreateDateTimeBefore?user=${userIDLink}&createDateStart=${startDateISO}&createDateEnd=${endDateISO}&sort=${NEW_FIRST}&size=${fetchSize}`;
  return await axiosGet(currMonthEndpoint);
};

const getUsersLatestFinData = async (userIDLink) => {
  try {
    const fetchFinData = await Promise.all([
      getUsersFinDataByRange(userIDLink, { all: true }),
      // getUsersFinDataByRange(userIDLink, { prevMonth: true }),
    ]);
    const [currMonthRes] = fetchFinData;
    // const lastMonthsData = lastMonthRes.data._embedded.finDatas;
    return {
      //CurrMonth is now "Latest" finData, need to verify if anything will be broken, possibly credit score change...
      currMonth: currMonthRes.data._embedded.finDatas[0],
      // lastMonthEnd: lastMonthsData?.[0],
      // lastMonthStart: lastMonthsData?.[lastMonthsData.length - 1],
    }; //Last month is optional (ie start of loan it will not be present)
  } catch (error) {
    throw error;
  }
};

const getAllUsersFinData = async (uid) => {
  const endpoint = `/users/${uid}/finDatas`;
  return axiosGet(endpoint);
};

const getUsersGoalStatusForMonth = async (userID) => {
  //uses header JWT to determine the user
  //Used to determine if the user paid for this month.
  // const endpoint = `/users/getGoalsStatus`;
  const endpoint = `/users/${userID}/getGoalsStatus`;
  try {
    return await axios.get(endpoint);
  } catch (e) {
    // ! Server messaging is sparse, when there is no fin data to compare server side it throws a 500 error
    // With this exception return null, this will be interpreted as no payment yet.
    if (e.response.status === 500) {
      return null;
    }
    throw e;
  }
  // await axiosGet(endpoint);
};

const getUsersFlinksLoginData = async (uid) => {
  const endpoint = `/users/${uid}/flinksLogins`;
  return axiosGet(endpoint);
};
/* 
const getUsersFlinksAccountData = async (uid) => {
  const endpoint = `/users/${uid}/flinksAccounts`;
  return axiosGet(endpoint);
};
//ENDPOINT DOESNT EXIST...
 */
const submitFlinksLogin = async (flinksData) => {
  if (!flinksData) throw new Error("API call missing required data");
  const endpoint = `/flinks/submitLoginID`;

  try {
    return await axios.post(endpoint, flinksData);
  } catch (error) {
    throw error;
  }
};

const userCompleteOnboarding = async (userID) => {
  const endpoint = `/users/${userID}`;
  try {
    return await axios.patch(endpoint, { completeOnBoard: true });
  } catch (error) {
    throw error;
  }
};

const userPatch = async (userID, body) => {
  const endpoint = `/users/${userID}`;
  try {
    return await axios.patch(endpoint, body);
  } catch (error) {
    throw error;
  }
};

const postMonthlyAssessment = async (body) => {
  const endpoint = `/selfAssessments/`;
  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};

const patchMonthlyAssessment = async (assessmentID, body) => {
  const endpoint = `/selfAssessments/${assessmentID}`;
  try {
    return await axios.patch(endpoint, body);
  } catch (error) {
    throw error;
  }
};

// const getUserAssessmentForLastMonth = async (userLink, dateRange) => {
//   const today = cookies.getDate();
//   const startOfLastMonth = startOfMonth(subMonths(today, 1));
//   const endOfLastMonth = endOfMonth(subMonths(today, 1));

//   const endpoint = `/selfAssessments/search/findAllByAssessmentMonthBetweenAndUser?searchDateStart=${formatISO(
//     startOfLastMonth
//   )}&searchDateEnd=${formatISO(endOfLastMonth)}&user=${userLink}`;
//   /* Verify if user specific */
//   return axiosGet(endpoint);
// };

const getUserAssessments = async (userLink, dateRange) => {
  //consolidate with lastMonths assessments
  const today = cookies.getDate();
  const startRange =
    dateRange?.startDate.toISOString() ||
    cookies.getDateByInput("01 January 2021").toISOString();
  const endRange = dateRange?.endDate.toISOString() || today;
  //[] Add size and sort to get 1st
  //[] ALL_USERS doesn't work, a link is required, it its missing it will only give an empty array (no error)

  let endpoint = `/selfAssessments/search/findAllByAssessmentMonthBetweenAndUser?searchDateStart=${startRange}&searchDateEnd=${endRange}`;
  if (userLink && userLink !== "ALL_USERS") endpoint += `&user=${userLink}`;
  /* Verify if user specific */
  return axiosGet(endpoint);
};

const getAllSelfAssessments = async () => {
  const endpoint = `/selfAssessments?sort=assessmentMonth,desc`;
  return axiosGet(endpoint);
};

const getCBHistory = async (UID, dateRange) => {
  const today = new Date();
  const startDate = dateRange?.startDate || "2022-01-01T00:00:00.000Z";
  const endDate = dateRange?.endDate || today.toISOString();
  const endpoint = `/users/${UID}/getCashbackHistory?startDate=${startDate}&endDate=${endDate}`;
  /* Verify if user specific */
  return axiosGet(endpoint);
};

const getUserNotifications = (userIDLink) => {
  const isDismissed = false;
  const endpoint = `/notifications/search/findAllByDismissedAndUser?dismissed=${isDismissed}&user=${userIDLink}`;
  return axiosGet(endpoint);
};

const patchNotification = async (notificationID, body) => {
  if (notificationID.includes("http")) {
    notificationID = parseIdFromLink(notificationID);
  }
  const endpoint = `/notifications/${notificationID}`;
  try {
    return await axios.patch(endpoint, body);
  } catch (error) {
    throw error;
  }
};

const getUserTlLoan = async (userID) => {
  const endpoint = `/users/${userID}/tlLoan`;
  try {
    const res = await axios.get(endpoint);
    const loanLink = res.data._links.self.href;
    return axiosGet(loanLink);
  } catch (error) {
    throw error;
  }
};

const getUsersCBRecords = async (userID) => {
  const endpoint = `/users/${userID}/cashbackRecords`;
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};

const getCashbackRecords = async (userID) => {
  const endpoint = `/cashbackRecords/users/${userID}/0/150`;
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};


const submitContactEmail = async (body) => {
  const endpoint = "/users/contactEmail";
  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};
const sap = async (body) => {
  const endpoint = "/sap";
  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};
const fetchSap = async (body) => {
  const endpoint = "/sap/" + body.userId + "/" + body.assessmentMonth;
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};
const fetchSapAdmin = async (body) => {
  const endpoint = "/sap/" + body.userId + "/" + body.assessmentMonth;
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};
const fetchTopic = async (body) => {
  const endpoint = "/topic/" + body.id + "/" + body.enabled + "/" + body.pageNo + "/" + body.pageSize;
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};
const fetchTopicDetails = async (body) => {
  const endpoint = "/topic/" + body.id;
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};
const findingImageURL = async (id) => {
  const endpoint = `/image/${id}`;
  try {
    // const res = await axios.get(endpoint);
    const res = await axios.get(endpoint, { responseType: 'blob' });
    return res;
  } catch (error) {
    throw error;
  }
}
const deleteImageDirectly = async (imageId) => {
  const endpoint = "/image/delete";
  const token = cookies.getJWT();
  try {
    // Create form-data object
    const formData = new FormData();
    formData.append("imageId", imageId);

    const res = await axios.request({
      url: endpoint,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data", // Form-data content type
      },
      data: formData, // Pass form-data in the body
    });
    return res;
  } catch (error) {
    console.error("Error:", error.response || error);
    throw error;
  }
};

const fetchCategoryDetails = async (body) => {
  const endpoint = "/category/" + body.id;
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};

const notificationStatus = async (body) => {
  const endpoint = "/action/notificationStatus/" + body.id;
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};

const appMetadata = async (body) => {
  const endpoint = "/firebase/appMetadata";
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};


const handleLikeSubmit = async (body) => {
  const endpoint = "/action/like";
  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};

const handleCommentSubmit = async (body) => {
  const endpoint = "/action/comment";
  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};
const handleDeleteSubmit = async (body) => {
  const endpoint = "/action/comment/" + body.id;
  try {
    return await axios.delete(endpoint);
  } catch (error) {
    throw error;
  }
};

const fetchRepliesByTopic = async (body) => {
  const endpoint = "/action/comment/" + body?.id + "/0/5000";
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};

const fetchLikesByTopic = async (body) => {
  const endpoint = "/action/like/" + body?.id + "/0/100000000";
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};

const uploadImageDirectly = async (body) =>{
  const endpoint = "/image/base64";
  // const formData = new FormData();
  // formData.append("imageId", body.imageId);
  // formData.append("file", body.file);
  const token = cookies.getJWT();
  // const formData = new FormData();
  // formData.append("imageId", body.imageId);
  // formData.append("file", body.file); 
  const formData = new FormData();
  formData.append("imageId", body.imageId); // Add the image ID
  formData.append("file", body.file);

  // Append each file in the `file` array
  

  try {
    return await axios.post(endpoint, formData, {
      headers: {
        // 'Content-Type': 'multipart/form-data;',
        'Authorization': `Bearer ${token}`

      }
    })} catch (error) {
    throw error;
  }
}

const uploadTopicImage = async (body, topicId, imageId) => {
  const endpoint = "/image/base64/topicId/imageId";
  try {
    return await axios.post(endpoint, body, {
      headers: {
        'Content-Type': 'multipart/form-data;'
      }
    });
  } catch (error) {
    throw error;
  }
};
const fetchCategory = async (body) => {
  const endpoint = "/category/" + body.enabled + "/" + body.pageNo + "/" + body.pageSize;
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};

const saveCategory = async (body) => {
  const endpoint = "/category";
  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};

const saveOption = async (body) => {
  const endpoint = "/action/usermetadata/" + body?.userId + "/disableNotification/" + body?.option;
  try {
    return await axios.post(endpoint, {});
  } catch (error) {
    throw error;
  }
};

const enableNotification = async (body) => {
  const endpoint = "/action/enableNotification/" + body?.userId + "/" + localStorage.getItem('pushDeviceToken');
  try {
    return await axios.post(endpoint, {});
  } catch (error) {
    throw error;
  }
};
const disableNotification = async (body) => {
  const endpoint = "/action/disableNotification/" + body?.userId;
  try {
    return await axios.post(endpoint, {});
  } catch (error) {
    throw error;
  }
};
const saveTopic = async (body) => {
  const endpoint = "/topic";
  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};

const submitApplication = async (body) => {
  const endpoint = "/applicants";
  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};

const updateApplication = async (body, id) => {
  if (body?.appID) {
    id = parseInt(body.appID);
  }
  const endpoint = `/applicants/${id}`;
  delete body["hubspotID"]; //Dont send HS ID in patch, only POST to trigger overwrite in HS.  Patching can cause the record to get orphaned because of old HS IDs
  console.log("body", body);
  try {
    return await axios.patch(endpoint, body);
  } catch (error) {
    throw error;
  }
};


const updateVistorDetails = async (body) => {
  let endpoint = `/visitor/create`;
  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};

const findApplicationByEmail = async (userEmail) => {
  if (!userEmail) throw new Error("Api requires email address to search");
  const endpoint = `/applicants/search/findAllByEmail?email=${userEmail}&sort=createDateTime,desc`;
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};

const deleteFlinksAccount = async (id) => {
  const endpoint = `/flinksAccounts/${id}`;
  try {
    return await axios.delete(endpoint);
  } catch (error) {
    throw error;
  }
};

const deleteFlinksLogin = async (id) => {
  const endpoint = `/flinksLogins/${id}`;
  try {
    return await axios.delete(endpoint);
  } catch (error) {
    throw error;
  }
};
/* ============================================Admin Calls... ================================================ */

const saveFinData = async (body) => {
  /* body ={user: userLink, utilizedCredit, totalCreditAvailable} */
  const endpoint = "/finDatas";
  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};

const saveWellnessSurvey = async (body) => {
  /* body ={user: userLink, 
  folowed by answers a0 to a5, or 'score' for direct entry} */
  const endpoint = "/wellnessScores";
  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};

const getUsersWellnessScores = async (userID) => {
  if (!userID) throw new Error("Requires userID to search");
  const endpoint = `/users/${userID}/wellnessScores`;
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};

const getCoachingActivities = async (userID) => {
  if (!userID) throw new Error("Requires userID to search");
  const endpoint = `/coaching-activities/${userID}`;
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};
const setCoachingActivities = async (data) => {
  const endpoint = `/coaching-activities`;
  try {
    return await axios.post(endpoint,data);
  } catch (error) {
    throw error;
  }
};
const saveFICOScore = async (body) => {
  /* body: {user: link, ficoScore, pulledDate} */
  const endpoint = "/fICOScores";
  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};

const getUsersFICOScores = async (userID) => {
  if (!userID) throw new Error("Requires userID to search");
  const endpoint = `/users/${userID}/ficoScores`;
  try {
    return await axios.get(endpoint);
  } catch (error) {
    throw error;
  }
};

const uploadImage = async (formData) => {
  const endpoint = `/files?name=${formData.name}`;
  try {
    return await axios.post(endpoint, formData);
  } catch (error) {
    throw error;
  }
};
const createInspiration = async (body) => {
  const endpoint = `/inspirations`;
  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};

const deleteInspiration = async (inspID) => {
  const endpoint = `/inspirations/${inspID}`;
  try {
    return await axios.delete(endpoint);
  } catch (error) {
    throw error;
  }
};
const createQuiz = async (body) => {
  const endpoint = `/quizzes`;
  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};
const createAssessment = async (body) => {
  const endpoint = `/selfAssessments/add`;
  try {
    const response = await axios.post(endpoint, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}
const submitQuizAnswer = async (body) => {
  const endpoint = `/quizAnswer/add`;
  try {
    const response = await axios.post(endpoint, body);
     
    return response.data;
  } catch (error) {
    throw error;
  }
}
const checkUser = async (userName) => {
  try {
    // Construct the endpoint dynamically with the username
    console.log("userName",userName);
    const email = userName.email;
    const endpoint = `/user/${email}`;
    const res = await axios.get(endpoint); // Use GET method for user validation
    
    return res.data;
  } catch (e) {
    console.error("User validation error:", e);
    throw e;
  }
};
const validateQuiz = async (Quizid) => {
  try {
   // const id = Quizid.id;
    const endpoint = `/quiz/${Quizid}`;
    const res = await axios.get(endpoint); // Use GET method for user validation
    
    return res.data;
  } catch (e) {
    console.error("User validation error:", e);
    throw e;
  }
};
const editQuiz = async (quizID, body) => {
  const endpoint = `/quizzes/${quizID}`;
  try {
    return await axios.patch(endpoint, body);
  } catch (error) {
    throw error;
  }
};

const createUser = async (body) => {
  const endpoint = `/users`;
  //Add required default fields for creating a user

  function makePass(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@$%&";
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const env = cookies.processenv.REACT_APP_ENV;
  const api = cookies.processenv.REACT_APP_API;
  body["enabled"] = true;
  body["tcAccepted"] = true;
  body["verified"] = true;
  body["password"] = env === "api.dev" ? "P@ssword" : makePass(16);
  body["role"] = `https://${env}.${api}roles/9`;

  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};

const createUserByUserType = async (body) => {
  const endpoint = `/users`;
  //Add required default fields for creating a user

  function makePass(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@$%&";
    const charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const env = cookies.processenv.REACT_APP_ENV;
  const api = cookies.processenv.REACT_APP_API;
  body["enabled"] = true;
  body["tcAccepted"] = true;
  body["verified"] = true;
  body["password"] = env === "api.dev" ? "P@ssword" : makePass(16);
  switch (body?.userType) {
    case 'USER':
      body["role"] = `https://${env}.${api}/roles/9`;
      break;
    case 'EMPLOYEE':
      body["role"] = `https://${env}.${api}/roles/7`;
      break;
    case 'FREEMIUM':
      body["role"] = `https://${env}.${api}/roles/8`;
      break;
    default:
      body["role"] = `https://${env}.${api}/roles/9`;
      break;
  }

  try {
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};



const userImportTKL = async (userID) => {
  const endpoint = `/users/${userID}/TKLImport`;
  // const endpoint = `/TLImport?username=${userID}`;
  try {
    // return 'success';
    return await axios.post(endpoint);
  } catch (error) {
    throw error;
  }
};

const activateUser = async (body) => {
  const endpoint = `/users/activate`;
  try {
    // return 'success';
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};


const submitPartner = async (body) => {
  const endpoint = `/set-partner`;
  try {
    // return 'success';
    return await axios.post(endpoint, body);
  } catch (error) {
    throw error;
  }
};
const deleteQuiz = async (quizID) => {
  const endpoint = `/quizzes/${quizID}`;
  try {
    return await axios.delete(endpoint);
  } catch (error) {
    throw error;
  }
};

const getUserByEmail = async (emailToFind) => {
  return axiosGet(`/users/search/multiParam?username=${emailToFind}`);
};

const getAllUsersByPage = async () => {
  let endpoint = `/users?sort=${"lastName,desc"}&size=${100}&page=${0}`;

  return axiosGet(endpoint);
};
const getAllUsersSortByfirstNameByPage = async () => {
  let endpoint = `/users?sort=${"lastName,desc"}&size=${50}&page=${0}`;

  return axiosGet(endpoint);
};
const getAllUsers = async (pageSize, pageNo) => {
  let endpoint = `/users?sort=${"username,desc"}&size=${pageSize}&page=${pageNo}`;

  return axiosGet(endpoint);
};

const getAllUsersByUserType = async (pageSize, pageNo, userType = 'USER') => {
  let endpoint = `/users?sort=${"username,desc"}&size=${pageSize}&page=${pageNo}&userType=${userType}`;

  return axiosGet(endpoint);
};


const findAllByUserType = async (pageSize = 20, pageNo = 0, userType = 'USER') => {
  let endpoint = `/users/search/findAllByUserType`;
  try {

    let params = {
      "filterOptions": {
        "userType": userType || 'USER'
      },
      "pageNumber":  pageNo >= 0 ? pageNo : 0,
      "pageSize": pageSize > 0 ? pageSize : 20,
      "sortBy": "firstName",
      "sortOrder": "ASC"
    }
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    console.error("Error fetching users by type:", error);
    throw error;
  }
};

const findAllQuiz = async (pageSize = 31, pageNo = 0) => {
  let endpoint = `/quiz/` + pageNo + `/` + pageSize;
  try {


    return await axios.get(endpoint).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const findAllByDateRangeByUserType = async (pageSize = 31, pageNo = 0, data, userType = 'USER') => {
  let endpoint = `/users/search/findAllByUserType`;
  try {

    let params = {
      "filterOptions": {
        "month": data?.month || 5,
        "year": data?.year || 2023,
        "userType": userType || 'USER'
      },
      "pageNumber": pageNo,
      "pageSize": pageSize,
      "sortBy": "firstName",
      "sortOrder": "ASC"
    }
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};



const getAllApplicants = async (pageSize, pageNo) => {
  let endpoint = `/applicants?sort=${"username,desc"}&size=${pageSize}&page=${pageNo}`;

  return axiosGet(endpoint);
};



const downloadReport = (data = null) => {
  let endpoint = `/tgiReportingRequirements`;
  try {
    let params = {
    }
    return axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const enableDisableUser = (data) => {
  let endpoint = `/users/${data?.userID}/status/${data?.flag}`;
  try {
    let params = {
    }
    return axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const downloadTGIReport = async (data) => {
  const endpoint = `/report/tgi`;

  try {
    const response = await axios.post(endpoint, data, {
      responseType: 'blob', // This is important to handle binary data
    });

    // Create a new Blob object using the response data
    const blob = new Blob([response.data], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = url;

    // Set the download attribute with a filename
    link.setAttribute('download', 'tgi_report.xlsx'); // You can specify the desired file name

    // Append the anchor element to the body and trigger a click to download
    document.body.appendChild(link);
    link.click();

    // Clean up the temporary anchor element and URL object
    link.remove();
    window.URL.revokeObjectURL(url);

    return response;
  } catch (error) {
    console.error('Error downloading the tgi report:', error);
    throw error;
  }
};

const downloadserviceReport = async (data) => {
  const endpoint = `/report/spartan`;

  try {
    const response = await axios.post(endpoint, data, {
      responseType: 'blob', // This is important to handle binary data
    });

    // Create a new Blob object using the response data
    const blob = new Blob([response.data], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = url;

    // Set the download attribute with a filename
    link.setAttribute('download', 'export.csv'); // You can specify the desired file name

    // Append the anchor element to the body and trigger a click to download
    document.body.appendChild(link);
    link.click();

    // Clean up the temporary anchor element and URL object
    link.remove();
    window.URL.revokeObjectURL(url);

    return response;
  } catch (error) {
    console.error('Error downloading the tgi report:', error);
    throw error;
  }
};
const downloadWLUReport = async (data) => {
  const endpoint = `/report/wlu1`;

  try {
    const response = await axios.post(endpoint, data, {
      responseType: 'blob', // This is important to handle binary data
    });

    // Create a new Blob object using the response data
    const blob = new Blob([response.data], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = url;

    // Set the download attribute with a filename
    link.setAttribute('download', 'wlu_report.xlsx'); // You can specify the desired file name

    // Append the anchor element to the body and trigger a click to download
    document.body.appendChild(link);
    link.click();

    // Clean up the temporary anchor element and URL object
    link.remove();
    window.URL.revokeObjectURL(url);

    return response;
  } catch (error) {
    console.error('Error downloading the wlu report:', error);
    throw error;
  }
};
const downloadCashFlowReportByType = async (data) => {
  const endpoint = `/report/cashFlow`;

  try {
    const response = await axios.post(endpoint, data, {
      responseType: 'blob', // This is important to handle binary data
    });

    // Create a new Blob object using the response data
    const blob = new Blob([response.data], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);

    // Create a temporary anchor element
    const link = document.createElement('a');
    link.href = url;

    // Set the download attribute with a filename
    link.setAttribute('download', 'cash_flow_report.xlsx'); // You can specify the desired file name

    // Append the anchor element to the body and trigger a click to download
    document.body.appendChild(link);
    link.click();

    // Clean up the temporary anchor element and URL object
    link.remove();
    window.URL.revokeObjectURL(url);

    return response;
  } catch (error) {
    console.error('Error downloading the cash flow report:', error);
    throw error;
  }
};
const downloadReportByUserType = (data = null, userType = 'USER') => {
  let endpoint = `/tgiReportingRequirements?userType=${userType}`;
  try {
    let params = {
    }
    return axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};


const downloadServicerReport = (data = null, userType = 'USER') => {
  let endpoint = `/export-spartan-report/export-csv`;
  try {
    let params = {
    }
    return axios.get(endpoint).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};


const insuranceEnrollment = async (userID) => {

  let endpoint = `/insuranceEnrollment/` + userID;
  return axiosGet(endpoint);
};


const insuranceEnrollmentPost = async (userID, data) => {
  let endpoint = `/insuranceEnrollment/` + userID;
  try {
    let params = {
      "enrolled": data.enrolled,
    }
    return axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};


const insuranceEnrollmentPut = async (userID, data) => {
  let endpoint = `/insuranceEnrollment/` + userID;
  try {
    let params = {
      enrolled: data.enrolled,
      paymnetFrequency: data.paymnetFrequency,
      enrollmentDate: data.enrollmentDate,
    }


    return axios.put(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const findByCustomerReferenceId = async (customerReferenceId) => {
  let endpoint = `applicants/search/findByCustomerReferenceId?customerReferenceId=${customerReferenceId}`;

  return axiosGet(endpoint);
};

const findAllByEmail = async (email, days) => {
  let endpoint = `applicants/search/findAllByEmail?email=${email}&days=${days}`;

  return axiosGet(endpoint);
};
const searchByEmail = async (email, days) => {
  let endpoint = `applicants/searchByEmail?email=${email}&days=${days}`;

  return axiosGet(endpoint);
};
function switchSort(sortOrder) {
  try {
    let sort_order = sortOrder.toLowerCase();
    if (sortOrder === 'asc') {
      return 'ASC';
    } else if (sortOrder === 'desc') {
      return 'DSC';
    } else if (sortOrder === 'dsc') {
      return 'DSC';
    } else {
      return sort_order.toUpperCase();
    }
  } catch (error) {
    return sortOrder;
  }
}
function switchSortBy(sortBy) {
  try {
    if (sortBy === 'utmSource') {
      return 'initial_utm_source';
    } else if (sortBy === 'applicantId') {
      return 'id';
    } else {
      return sortBy;
    }
  } catch (error) {
    return sortBy;
  }
}

const fetchByLeadFilter = async (pageSize, pageNo, data = null, sortBy = null, sortOrder = null) => {
  let endpoint = `/fetchByLeadFilter`;

  try {
    let params = {
      "filter": {
        "email": data?.email ? data?.email : "",
        "firstName": data?.firstName ? data?.firstName : "",
        "fromDate": (data?.fromDate ? data?.fromDate : "1991-02-15T00:00:00.000Z").toISOString().replace('Z', ''),
        "lastName": data?.lastName ? data?.lastName : "",
        "leadStatus": data?.leadStatus ? data?.leadStatus : "",
        "toDate": (data?.toDate ? data?.toDate : "2050-02-15T23:59:59.999Z").toISOString().replace('Z', ''),
        "utmSource": data?.utmSource ? data?.utmSource : ""
      },
      "pageNo": pageNo,
      "pageSize": pageSize,
      "sortBy": sortBy ? switchSortBy(sortBy) : "createDateTime",
      "sortOrder": sortOrder ? switchSort(sortOrder) : "DSC"
    };
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const exportCSVByFilter = async (data = null) => {
  let endpoint = `/exportCSVByFilter`;
  try {
    let params = {
      "filter": {
        "email": data?.email ? data?.email : "",
        "firstName": data?.firstName ? data?.firstName : "",
        "fromDate": (data?.fromDate ? data?.fromDate : "1991-02-15T00:00:00.000Z").toISOString().replace('Z', ''),
        "lastName": data?.lastName ? data?.lastName : "",
        "leadStatus": data?.leadStatus ? data?.leadStatus : "",
        "toDate": (data?.toDate ? data?.toDate : "2030-02-15T23:59:59.999Z").toISOString().replace('Z', ''),
        "utmSource": data?.utmSource ? data?.utmSource : "",
      },
      "pageNo": "",
      "pageSize": "",
      "sortBy": "createDateTime",
      "sortOrder": "DSC"
    };
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const refreshLeadStatus = async (applicantId) => {
  let endpoint = `/refreshLeadStatus/` + applicantId;
  try {
    let params = {
      "applicantId": applicantId
    };
    return await axios.put(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const getTimeline = async (urlEmail) => {
  if (urlEmail) {
    let endpoint = `/cif/` + urlEmail;

    return axiosGet(endpoint);
  } else {
    return null;
  }

};

const getFirebaseConfig = async () => {
  let endpoint = `/firebase/getCredentials`;
  return axiosGet(endpoint);
};
const sendNotification = async (data = null) => {
  let endpoint = `/firebase/sendNotification`;
  try {
    let params = {
      "body": data
    };
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const readMessage = async (data = null) => {

  let endpoint = `/firebase/updateStatus/${data.uid}`;
  try {
    let data1 = {
      chatRoomId: data.chatRoomId
    }
    return await axios.put(endpoint, data1).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};
const readMessageNew = async (data = null) => {

  let endpoint = `/message/read/${data.id}`;
  try {
    let data1 = {
    }
    return await axios.post(endpoint, data1).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};
const updateCashbackRecord = async (id, body) => {
  const endpoint = `/cashbackRecords/${id}`;
  try {
    return await axios.patch(endpoint, body);
  } catch (error) {
    throw error;
  }
};

const deleteUser = async (id) => {
  const endpoint = `/users/${id}`;
  try {
    return await axios.delete(endpoint);
  } catch (error) {
    throw error;
  }
};

const deleteFICOScore = async (id) => {
  const endpoint = `/fICOScores/${id}`;
  try {
    return await axios.delete(endpoint);
  } catch (error) {
    throw error;
  }
};


const weeklyAnalytics = () => {
  const endpoint = "/weeklyAnalytics";
  try {
    return axios.get(endpoint).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const weeklyLoanSummary = () => {
  const endpoint = "/weeklyLoanSummary";
  try {
    return axios.get(endpoint).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};
const fetchSummaryByLeadFilter = async (data = null) => {
  let endpoint = `/fetchSummaryByLeadFilter`;
  try {
    let params = {
      "filter": {
        "email": data?.email ? data?.email : "",
        "firstName": data?.firstName ? data?.firstName : "",
        "fromDate": (data?.fromDate ? data?.fromDate : "1991-02-15T00:00:00.000Z").toISOString().replace('Z', ''),
        "lastName": data?.lastName ? data?.lastName : "",
        "leadStatus": data?.leadStatus ? data?.leadStatus : "",
        "toDate": (data?.toDate ? data?.toDate : "2030-02-15T23:59:59.999Z").toISOString().replace('Z', ''),
        "utmSource": data?.utmSource ? data?.utmSource : "",
      },
      "pageNo": 0,
      "pageSize": 0,
      "sortBy": "createDateTime",
      "sortOrder": "DSC"
    };
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const fetchReportTrade = async (data = null) => {
  let endpoint = `/batch/report/trades/` + data?.userId + "/" + data?.date
  try {

    return await axios.get(endpoint).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};


const findAllActiveUsersInMonth = async (data = null, pageNo = 0, pageSize = 10) => {
  let endpoint = `/users/search/findAllActiveUsersInMonth`;
  try {
    let params = {
      "filterOptions": {
        "month": data?.month || 5,
        "year": data?.year || 2023
      },
      "pageNumber": pageNo,
      "pageSize": pageSize,
      "sortBy": "firstName",
      "sortOrder": "ASC"
    }
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const findAllUsersWithInspirationCompleted = async (data = null, pageNo = 0, pageSize = 10) => {
  let endpoint = `/inspirationAnswers/search/findAllUsersWithInspirationCompleted`;
  try {
    let params = {
      "filterOptions": {
        "month": data?.month || 5,
        "year": data?.year || 2023
      },
      "pageNumber": pageNo,
      "pageSize": pageSize,
      "sortBy": "firstName",
      "sortOrder": "ASC"
    }
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const findAllUsersWithMoodCompleted = async (data = null, pageNo = 0, pageSize = 10) => {
  let endpoint = `/moodAnswers/search/findAllUsersWithMoodCompleted`;
  try {
    let params = {
      "filterOptions": {
        "month": data?.month || 5,
        "year": data?.year || 2023
      },
      "pageNumber": pageNo,
      "pageSize": pageSize,
      "sortBy": "firstName",
      "sortOrder": "ASC"
    }
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const findSelfAssessmentCountInMonth = async (data = null, pageNo = 0, pageSize = 31) => {
  let endpoint = `/selfAssessments/search/findSelfAssessmentCountInMonth`;
  try {
    return await axios.get(endpoint).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const findAllCompletedSelfAssessmentInMonth = async (data = null, pageNo = 0, pageSize = 31) => {
  let endpoint = `/selfAssessments/search/findAllCompletedSelfAssessmentInMonth`;
  try {

    let params = {
      "filterOptions": {
        "month": data?.month || 5,
        "year": data?.year || 2023
      },
      "pageNumber": pageNo,
      "pageSize": pageSize,
      "sortBy": "firstName",
      "sortOrder": "ASC"
    }
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};
// const findAllCompletedSelfAssessmentInMonthByUserType = async (data = null, pageNo = 0, pageSize = 31, userType = 'USER') => {
const getAllChatRoomIds = async () => {
  let endpoint = `/firebase/getAllChatRoomIds`;
  try {

    // let params = {
    //   "filterOptions": {
    //     "month": data?.month || 5,
    //     "year": data?.year || 2023,
    //     "userType": userType || 'USER'
    //   },
    //   "pageNumber": pageNo,
    //   "pageSize": pageSize,
    //   "sortBy": "firstName",
    //   "sortOrder": "ASC"
    // }
    return await axios.get(endpoint).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const getAllChatRoom = async (data) => {
  let endpoint = `/listChatRoom/` + data?.page + `/` + data?.size;
  try {

    // let params = {
    //   "filterOptions": {
    //     "month": data?.month || 5,
    //     "year": data?.year || 2023,
    //     "userType": userType || 'USER'
    //   },
    //   "pageNumber": pageNo,
    //   "pageSize": pageSize,
    //   "sortBy": "firstName",
    //   "sortOrder": "ASC"
    // }
    return await axios.get(endpoint).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};
const behaviourActivity = async () => {
  try {
    let endpoint = `/users/behaviourActivity`;
    try {

      return await axios.get(endpoint).then((res) => {
        return res;
      });
    } catch (error) {
      throw error;
    }
  } catch (error) {
    console.log(error);
  }

};

const unreadMessageCount = async () => {
  try {
    let endpoint = `/users/unreadMessageCount`;
    try {

      return await axios.get(endpoint).then((res) => {
        return res;
      });
    } catch (error) {
      throw error;
    }
  } catch (error) {
    console.log(error);
  }

};
const unreadMessageCountNew = async (data) => {

  try {
    let endpoint = `/chatRoom/getUnreadMessagesCount/` + data?.userId;
    try {
      return await axios.get(endpoint).then((res) => {
        return res;
      });
    } catch (error) {
      throw error;
    }
  } catch (error) {
    console.log(error);
  }

};
const sendMessageNew = async (data) => {

  try {
    let endpoint = `/message`;
    try {

      return await axios.post(endpoint, data).then((res) => {
        return res;
      });
    } catch (error) {
      throw error;
    }
  } catch (error) {
    console.log(error);
  }

};

const mxDetails = async (userID) => {
  let endpoint = `/users/mxuser/` + userID;
  try {

    return await axios.get(endpoint).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const getChatSuggestions = async () => {
  let endpoint = `/firebase/getChatSuggestions`;
  try {

    return await axios.get(endpoint).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};
const getChatSuggestionsNew = async (data) => {
  let endpoint = `/chatRoom/` + data?.userId + `/` + data?.adminId;
  try {

    return await axios.get(endpoint).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const createChatRoom = async (data) => {
  let endpoint = `/chatRoom/` + data?.adminId + `/` + data?.userId;
  try {

    return await axios.post(endpoint).then((res, { }) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const getChatMessages = async (data) => {
  let endpoint = `/messages/` + 0 + `/` + 100;
  try {

    return await axios.post(endpoint, data).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const findAllCompletedSelfAssessmentInMonthByUserType = async (data = null, pageNo = 0, pageSize = 31, userType = 'USER') => {
  let endpoint = `/selfAssessments/search/findAllCompletedSelfAssessmentInMonth`;
  try {

    let params = {
      "filterOptions": {
        "month": data?.month || 5,
        "year": data?.year || 2023,
        "userType": userType || 'USER'
      },
      "pageNumber": pageNo,
      "pageSize": pageSize,
      "sortBy": "firstName",
      "sortOrder": "ASC"
    }
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const findAllUsersRewardsDetails = async (data = null, pageNo = 0, pageSize = 10) => {
  let endpoint = `/cashbackRecords/search/findAllUsersRewardsDetails`;
  try {
    let params = {
      "filterOptions": {
        "month": data?.month || 5,
        "year": data?.year || 2023
      },
      "pageNumber": pageNo,
      "pageSize": pageSize,
      "sortBy": "firstName",
      "sortOrder": "ASC"
    }
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const findAllUsersWithOneQuizCompleted = async (data = null, pageNo = 0, pageSize = 10) => {
  let endpoint = `/quizAnswers/search/findAllUsersWithOneQuizCompleted`;
  try {
    let params = {
      "filterOptions": {
        "month": data?.month || 5,
        "year": data?.year || 2023
      },
      "pageNumber": pageNo,
      "pageSize": pageSize,
      "sortBy": "firstName",
      "sortOrder": "ASC"
    }
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const findAllUsersWithAllQuizCompleted = async (data = null, pageNo = 0, pageSize = 10) => {
  let endpoint = `/quizAnswers/search/findAllUsersWithAllQuizCompleted`;
  try {

    let params = {
      "filterOptions": {
        "month": data?.month || 5,
        "year": data?.year || 2023
      },
      "pageNumber": pageNo,
      "pageSize": pageSize,
      "sortBy": "u.first_name",
      "sortOrder": "ASC"
    }
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};




/*new */

const findAllLoggedInUsersByDate = async (data = null, pageNo = 0, pageSize = 10) => {

  // const today = cookies.getDate();
  const endDate = '2023-05-23';
  let endpoint = `/users/search/findAllLoggedInUsersByDate?date=` + endDate;
  try {

    let params = {
      "filterOptions": {
        "month": data?.month || 5,
        "year": data?.year || 2023
      },
      "pageNumber": pageNo,
      "pageSize": pageSize,
      "sortBy": "firstName",
      "sortOrder": "ASC"
    }
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};
const findAllNotLoggedInUsersByDate = async (data = null, pageNo = 0, pageSize = 10) => {

  // const today = cookies.getDate();

  const endDate = '2023-05-23';
  let endpoint = `/users/search/findAllNotLoggedInUsersByDate?date=` + endDate;
  try {

    let params = {
      "filterOptions": {
        "month": data?.month || 5,
        "year": data?.year || 2023
      },
      "pageNumber": pageNo,
      "pageSize": pageSize,
      "sortBy": "firstName",
      "sortOrder": "ASC"
    }
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};
const findAllUsersByCompleteOnBoarding = async (data = null, pageNo = 0, pageSize = 10) => {

  let endpoint = `/users/search/findAllUsersByCompleteOnBoarding`;
  try {

    let params = {
      "pageNumber": pageNo,
      "pageSize": pageSize,
      "sortBy": "first_name",
      "sortOrder": "ASC"
    }
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};
const findAllUsersByNotCompleteOnBoarding = async (data = null, pageNo = 0, pageSize = 10) => {

  let endpoint = `/users/search/findAllUsersByNotCompleteOnBoarding`;

  try {

    let params = {
      "pageNumber": pageNo,
      "pageSize": pageSize,
      "sortBy": "first_name",
      "sortOrder": "ASC"
    }
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};


const findCountOfLoggedInUsersPerWeek = async (data = null, pageNo = 0, pageSize = 10) => {

  let endpoint = `/users/search/findCountOfLoggedInUsersPerWeek?week=3&year=2023`;

  try {
    return await axios.get(endpoint).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};
const findCountOfLoggedInUsersPerMonth = async (data = null, pageNo = 0, pageSize = 10) => {

  let endpoint = `/users/search/findCountOfLoggedInUsersPerMonth?month=5&year=2023`;

  try {

    return await axios.get(endpoint).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

const listMXConnectionStatusesByAccount = async (userID) => {

  let endpoint = `/users/listMXConnectionStatusesByAccount/` + userID;

  try {

    return await axios.get(endpoint).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

/*new*/

const findRewardsCountOfUsersInMonth = async (data = null, pageNo = 0, pageSize = 10) => {
  let endpoint = `/cashbackRecords/search/findRewardsCountOfUsersInMonth`;
  try {
    let params = {
      "filterOptions": {
        "month": data?.month || 5,
        "year": data?.year || 2023
      },
      "pageNumber": pageNo,
      "pageSize": pageSize,
      "sortBy": "firstName",
      "sortOrder": "ASC"
    }
    return await axios.post(endpoint, params).then((res) => {
      return res;
    });
  } catch (error) {
    throw error;
  }
};

// const findAllUsersWithOneQuizCompleted1 = async (data = null, pageNo = 0, pageSize = 10) => {
//   let endpoint = `/quizAnswers/search/findAllUsersWithOneQuizCompleted?month=` + 5 + `&page=` + pageNo + `&size=` + pageSize + `&sort=ASC` + `&year=` + 2023;
//   try {
//     return await axios.get(endpoint).then((res) => {
//       return res;
//     });
//   } catch (error) {
//     throw error;
//   }
// };


const exports = {
  axiosGet,
  getAppData,
  getKeys,
  makeAxiosCall,
  authenticate,
  saveMXBankAccntApi,
  memberConnectionStatus,
  spendSummaryWidgetUrlApi,
  login,
  updatePass,
  getUserData,
  getUserRole,
  sendReset,
  changePassword,
  getQuizzesByDates,
  getQuizzesByDatesByUserType,
  getUsersQuizAnswers,
  getAllUsersQuizAnswers,
  postQuizAnswer,
  getTodaysInspiration,
  getMonthsInspiration,
  getLatestInspiration,
  postInspiration,
  getInspirationCommunityResponses,
  getUsersInspirationAnswerForToday,
  getInspireAnswerNewLogic,
  getRemoteImage,
  getUsersInspirationAnswerForTodayPaginated,
  getUsersMoodAnswerForToday,
  postMood,
  getMoodCommunityResponses,
  getUsersFinDataByRange,
  getUsersLatestFinData,
  getAllUsersFinData,
  getUsersGoalStatusForMonth,
  // getUsersFlinksAccountData,
  getUsersFlinksLoginData,
  submitFlinksLogin,
  userCompleteOnboarding,
  userPatch,
  postMonthlyAssessment,
  patchMonthlyAssessment,
  // getUserAssessmentForLastMonth,
  getUserAssessments,
  getAllSelfAssessments,
  getCBHistory,
  getUserNotifications,
  patchNotification,
  getUserTlLoan,
  getUsersCBRecords,
  getCashbackRecords,
  submitContactEmail,
  sap,
  fetchSap,
  fetchSapAdmin,
  fetchTopic,
  fetchTopicDetails,
  fetchCategoryDetails,
  handleLikeSubmit,
  handleCommentSubmit,
  fetchRepliesByTopic,
  fetchLikesByTopic,
  handleDeleteSubmit,
  downloadserviceReport,
  fetchCategory,
  notificationStatus,
  appMetadata,
  saveTopic,
  saveCategory,
  saveOption,
  enableNotification,
  disableNotification,
  uploadTopicImage,
  submitApplication,
  updateApplication,
  findApplicationByEmail,
  deleteFlinksAccount,
  deleteFlinksLogin,
  //=============================Admin calls
  saveFinData,
  saveWellnessSurvey,
  getUsersWellnessScores,
  getCoachingActivities,
  setCoachingActivities,
  saveFICOScore,
  getUsersFICOScores,
  uploadImage,
  createInspiration,
  deleteInspiration,
  createQuiz,
  editQuiz,
  createUser,
  createUserByUserType,
  userImportTKL,
  activateUser,
  deleteQuiz,
  getUserByEmail,
  getAllUsersByPage,
  getAllUsersSortByfirstNameByPage,
  getAllUsers,
  getAllUsersByUserType,
  findAllByUserType,
  findAllQuiz,
  fetchAllUtmSources,
  fetchAllPartners,
  fetchTransactionDetails,

  fetchLeadStatuses,
  getAllApplicants,
  downloadReport,
  enableDisableUser,
  downloadReportByUserType,
  downloadCashFlowReportByType,
  downloadWLUReport,
  downloadTGIReport,
  downloadServicerReport,
  insuranceEnrollment,
  insuranceEnrollmentPost,
  insuranceEnrollmentPut,
  findByCustomerReferenceId,
  findAllByEmail,
  searchByEmail,
  exportCSVByFilter,
  getTimeline,
  updateCashbackRecord,
  deleteUser,
  deleteFICOScore,
  weeklyAnalytics,
  weeklyLoanSummary,
  fetchSummaryByLeadFilter,
  fetchReportTrade,
  /*newly added */
  findAllActiveUsersInMonth,
  findAllUsersWithInspirationCompleted,
  findAllUsersWithMoodCompleted,
  findSelfAssessmentCountInMonth,
  findAllUsersWithOneQuizCompleted,
  findAllUsersWithAllQuizCompleted,
  findRewardsCountOfUsersInMonth,
  findAllUsersRewardsDetails,

  findAllCompletedSelfAssessmentInMonth,
  findAllCompletedSelfAssessmentInMonthByUserType,

  findAllLoggedInUsersByDate,
  findAllNotLoggedInUsersByDate,
  findAllUsersByCompleteOnBoarding,
  findAllUsersByNotCompleteOnBoarding,


  findCountOfLoggedInUsersPerWeek,
  findCountOfLoggedInUsersPerMonth,


  listMXConnectionStatusesByAccount,
  getUsersMoodAnswerForTodayPaginated,

  /*newly added */
  fetchByLeadFilter,
  refreshLeadStatus,
  findAllByDateRangeByUserType,
  getFirebaseConfig,
  getAllChatRoomIds,
  getAllChatRoom,
  getChatSuggestions,
  getChatSuggestionsNew,
  createChatRoom,
  getChatMessages,
  sendMessageNew,
  behaviourActivity,
  unreadMessageCount,
  unreadMessageCountNew,
  mxDetails,
  sendNotification,
  readMessage,
  updateVistorDetails,
  readMessageNew,
  submitPartner,
  getPrefillData,
  createAssessment,
  submitQuizAnswer,
  checkUser,
  validateQuiz,
  uploadImageDirectly,
  findingImageURL,
  deleteImageDirectly
};
export default exports;
