import React, { useState } from 'react';

import { useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp, faTrash } from '@fortawesome/free-solid-svg-icons';

import Spinner from 'react-bootstrap/Spinner';
import cookies from '../utils/cookies';
import { faCheck, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import useAuth, { AuthProvider } from '../hooks/useAuth';
import { toast } from 'react-toastify';
import api from '../utils/api';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { random, set } from 'lodash';
// import arrow_left from '../../assets/icons/arrow_left.svg';
export default function ForumDetailsView() {
  let auth = useAuth();
  const savedRole = auth?.user?.role || cookies.getUserRole();
  const [data, setData] = useState(null);
  let topicId = window.location.pathname.split("/").pop();
  const [tID, setTID] = useState(topicId);
  const [replies, setReplies] = useState([]);
  const [likes, setLikes] = useState([]);
  const [liked, setLiked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isCommentLoading, setIsCommentLoading] = useState(false);
  const stripHtml = (html) => {
    if (!html) return ""; // Handle null/undefined cases

    // Decode HTML entities
    const textArea = document.createElement("textarea");
    textArea.innerHTML = html;
    const decodedHtml = textArea.value;

    // Remove HTML tags but preserve newlines for `<p>` and `<br>`
    const div = document.createElement("div");
    div.innerHTML = decodedHtml;

    // Replace block-level tags like `<p>` and `<br>` with newlines
    return div.textContent
      .replace(/<\/?p>/gi, "\n")
      .replace(/<br\s*\/?>/gi, "\n")
      .trim();
  };
  const fetchTopic = (topicId) => {

    if (!topicId || topicId === undefined || topicId === "undefined") {
      return;
    }

    let data = {

      "id": topicId
    };

    api.fetchTopicDetails(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('fetchTopicDetails', res1);
        setData(res1);


        fetchReplies(topicId);
        fetchLikes(topicId);
      }).catch(err => {
        // showToast(err?.response?.data?.message || 'No plan details to display');

        console.log('submit fetchTopic error', err);
      });


  };
  const fetchLikes = (topicId) => {

    if (!topicId || topicId === undefined || topicId === "undefined") {
      return;
    }

    let data = {

      "id": topicId
    };

    api.fetchLikesByTopic(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('fetchLikesByTopic', res1.content);
        setLikes(res1.content);
        console.log('fetchLikesByTopic', res1.content);
        for (let i = 0; i < res1.content.length; i++) {
          console.log('fetchLikesByTopic', res1.content[i]?.createdByUser, cookies.getUserID());
          if (res1.content[i]?.createdByUser.toString() === cookies.getUserID().toString()) {
            setLiked(true);
          }
        }
      }).catch(err => {
        // showToast(err?.response?.data?.message || 'No plan details to display');

        console.log('submit fetchLikesByTopic error', err);
      });


  };

  const fetchReplies = (topicId) => {

    if (!topicId || topicId === undefined || topicId === "undefined") {
      return;
    }

    let data = {

      "id": topicId
    };

    api.fetchRepliesByTopic(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('fetchRepliesByTopic', res1);
        setReplies(res1.content);
      }).catch(err => {
        // showToast(err?.response?.data?.message || 'No plan details to display');

        console.log('submit fetchRepliesByTopic error', err);
      });


  };

  const handleDeleteSubmit = (d) => {
    if (!d?.id) {
      return;
    }
    let id = d?.id;
    if (!id || id === undefined || id === "undefined") {
      return;
    }


    let data = {
      "id": id
    };
    api.handleDeleteSubmit(data).then(resp => resp?.data)
      .then(res1 => {
        console.log('handleDeleteSubmit', res1);
        toast.dismiss();
        toast.info('Deleted successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        fetchReplies(topicId);
      }).catch(err => {
        // showToast(err?.response?.data?.message || 'No plan details to display');

        console.log('submit fetchTopic error', err);
      });


  };
  const handleDSubmit = (d) => {
    if (!d?.id) {
      return;
    }
    let id = d?.id;
    if (!id || id === undefined || id === "undefined") {
      return;
    }


    let data = {
      "id": id,
      "createdByUser": cookies.getUserID(),
      "topicId": id,
      "name": cookies.getUserDisplayName(),
      "liked": false
    };
    setIsLoading(true);
    api.handleLikeSubmit(data).then(resp => resp?.data)
      .then(res1 => {
        setIsLoading(false);
        console.log('handleLikeSubmit', res1);
        toast.dismiss();
        toast.info('Unliked successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setLiked(false);
        fetchTopic(id);
      }).catch(err => {
        setIsLoading(false);
        // showToast(err?.response?.data?.message || 'No plan details to display');

        console.log('submit fetchTopic error', err);
      });


  };
  const handleLSubmit = (d) => {
    if (!d?.id) {
      return;
    }
    let id = d?.id;
    if (!id || id === undefined || id === "undefined") {
      return;
    }


    let data = {
      "id": id,
      "createdByUser": cookies.getUserID(),
      "topicId": id,
      "name": cookies.getUserDisplayName(),
      "liked": true
    };
    setIsLoading(true);
    api.handleLikeSubmit(data).then(resp => resp?.data)
      .then(res1 => {

        setIsLoading(false);
        console.log('handleLikeSubmit', res1);
        toast.dismiss();
        toast.info('Liked successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        setLiked(true);
        fetchTopic(id);
      }).catch(err => {

        setIsLoading(false);
        // showToast(err?.response?.data?.message || 'No plan details to display');

        console.log('submit fetchTopic error', err);
      });


  };

  useEffect(() => {
    fetchTopic(topicId);
    fetchReplies(topicId);
    fetchLikes(topicId);
  }, [topicId]);

  const article = {
    authorName: "John Doe",
    dateCreated: "February 29, 2024",
    title: "How To Build Your Financial Wellbeing & Wealth In Canada",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    imageUrl: "https://hardbacon.ca/_next/image/?url=https%3A%2F%2Fwpapi.hardbacon.ca%2Fwp-content%2Fuploads%2F2023%2F08%2FParachute-Debt-Consolidation.jpg&w=1200&q=75"
  };

  const [reply, setReply] = useState("");

  const handleReplyChange = (e) => {
    setReply(e.target.value);
  };

  const handleCommentSubmitFunc = () => {

    if (!reply || reply === undefined || reply === "undefined") {
      return;
    }

    setIsCommentLoading(true);
    //Random Alphanumeric with length 20
    var randomId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    let data = {
      "id": randomId,
      "createdByUser": cookies.getUserID(),
      "topicId": tID,
      "userName": cookies.getUserDisplayNameFirstS() || "",
      "text": reply
    };
    api.handleCommentSubmit(data).then(resp => resp?.data)

      .then(res1 => {

        console.log('handleCommentSubmit', res1);
        toast.dismiss();
        toast.info('Reply submitted successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setReply("");
        fetchTopic(tID);

        setIsCommentLoading(false);
      }).catch(err => {
        // showToast(err?.response?.data?.message || 'No plan details to display');

        setIsCommentLoading(false);
        console.log('submit fetchTopic error', err);
      });

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Reply submitted:", reply);
  };

  const handleGoBack = () => {
    //refirect to client forum
    window.location.href = "/client/forum";

  };
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [image, setImage] = useState(null);

  const nextSlide = () => {
    if (data?.uploadedImageIds.length === 0) return;
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % data.uploadedImageIds.length);
  };

  const prevSlide = () => {
    if (data?.uploadedImageIds.length === 0) return;
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? data.uploadedImageIds.length - 1 : prevIndex - 1
    );
  };
  const getRemoteImage = (imageId) => {
    return `https://${cookies.processenv.REACT_APP_ENV}.${cookies.processenv.REACT_APP_API}/image/` + imageId;
  }

  return (<>

    <Container id="contactForm" >

      <ButtonGroup>
        <Button variant='success' onClick={(e) => (window.location.href = '/client/forum')} style={{ borderRadius: '0px', background: 'grey' }}> &#x2190; Back</Button>
      </ButtonGroup>

    </Container>

    {data === null && <div>Loading...</div>}
    {data && data?.id && (<Container>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <Row>
        <Col>
          <Card style={{ width: '100%', background: 'transparent', border: 'transparent' }}>
            <Card.Body style={{ background: 'transparent', border: 'transparent' }}>
              <Card.Title>{data?.title}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Category: {data?.categoryTitle ? data?.categoryTitle : ''}</Card.Subtitle>

              {data?.tags && data?.tags?.toString() && (<Card.Subtitle className="mb-2 text-muted">Tags: {data?.tags?.toString()}</Card.Subtitle>)}
              <hr />
              <Row>
                <Col className="text-left">
                  <div>Date Created: {new Date(data?.createdDate?.seconds * 1000 + data?.createdDate?.nanos / 1000000).toLocaleString()}</div>
                </Col>
                <Col className="text-right">
                  {data?.authorName && (<div style={{ textAlign: 'end' }}>Author: {data?.authorName ? cookies.getUserDisplayNameFirstS(data?.authorName) : ''}</div>)}
                </Col>
              </Row>
              {/* <hr /> */}

            </Card.Body>
          </Card>

          {/* <h2>This article isn't about overnight success or shortcuts to riches. Instead, it's about embracing the truth: building wealth requires making wise financial choices today to pave the way for a financially healthy future.</h2> */}

          <br></br>
          <div className="article-image">
            {data?.networkImageUrls && data.networkImageUrls.length > 0 && data.networkImageUrls[0] &&

              (<img src={data?.networkImageUrls && data.networkImageUrls.length > 0 ? data.networkImageUrls[0] : (article?.imageUrl)} alt="Image" className="img-fluid" />
              )}
          </div>
          <div className="article-image">



            { data?.uploadedImageIds && data.uploadedImageIds.length > 0 && data.uploadedImageIds[0] &&

              (

                <div className="slider">
                  {/* Loop through the array using map() */}
                  {data.uploadedImageIds.map((item, index) => (
                    <>
                    <img src={getRemoteImage(item)} className="img-fluid" alt={`Slide ${index}`} />
                    </>
                  ))}


                </div>

              )}
            {/* <img src={images[currentImageIndex]} alt={`Slide ${currentImageIndex}`} /> */}

          </div>

          <br></br>


          <div className="article-content">


            <Row>
              <Col>
                {/* <p>{data?.description} </p> */}
                <pre style={{ whiteSpace: "pre-wrap", margin: 0, lineHeight: "0.9",padding:0}}>
                    {stripHtml(data?.description)}
                  </pre>
              </Col>
            </Row>
          </div>
          <hr></hr>
          <Row>
            <Col className="text-left">
              <div>Replies: {data?.commentCount ? data?.commentCount : 0}</div>
            </Col>
            <Col className="text-right">
              <div style={{ textAlign: 'end' }}>Likes: {data?.likeCount ? data?.likeCount : 0}</div>




            </Col>
          </Row>



          <div className="reply-section" style={{ textAlign: 'right' }}>


            {/* if likes has userid then show dislike button or show like button */}



            {isLoading ? <Spinner animation="border" size="sm" variant="success" /> : (<Button

              style={{
                // borderRadius: '0px',
                border: 'none',
                backgroundColor: liked ? '#FF745A' : '#24C95F', // Adjust colors based on liked state
                color: 'white', // Text color
              }}
              onClick={() => liked ? handleDSubmit(data) : handleLSubmit(data)}
            >
              <FontAwesomeIcon icon={liked ? faThumbsDown : faThumbsUp} /> {liked ? 'Unlike' : 'Like'}

            </Button>)}



            <hr></hr>

            Replies:
            <hr></hr>




            {replies && replies.length > 0 && replies.map((message, index) => (
              <div className={`chat-bubble mb-4 ${message.createdByUser.toString() === cookies.getUserID().toString() ? "" : "left"}`}>
                <div className="chat-bubble_content mb-2">
                  <div className="chat-bubble__right">
                    <div className="user-name"><small >
                      {`${cookies.getUserDisplayNameFirstS(message.authorName)}`}

                      
                    </small></div>
                    <div className="user-message">{message.text}</div>
                  </div>
                </div>
                <div className="d-flex fs-7">
                  <small className="message-time text-muted me-3">
                    {new Date(message?.createdDate?.seconds * 1000 + message?.createdDate?.nanos / 1000000).toLocaleString()} ✔
                  </small>
                  <small className="message-status text-success">{message.status}</small>
                </div>

                <br></br>
                {/* message.createdByUser.toString() === cookies.getUserID().toString() || */}
                {(savedRole === 'ROLE_SUPER_ADMIN') && (

                  <Button variant="danger" onClick={() => handleDeleteSubmit(message)}>
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </Button>

                )}


              </div>
            ))}

            <h2>Reply:</h2>
            <hr></hr>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Control as="textarea" value={reply} onChange={handleReplyChange} placeholder="Enter your reply..." />
              </Form.Group>
              <br></br>




              {isCommentLoading ? <Spinner animation="border" size="sm" variant="success" /> : (<Button variant="primary" type="submit" onClick={handleCommentSubmitFunc} disabled={reply ? false : true}>Submit</Button>)}
            </Form>
          </div>
        </Col>
      </Row>

      <br></br>

      <br></br>
    </Container>)}


  </>
  );
}
